import { AuthContext } from "@eduthings/app-scripts/models/security/AuthContext";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import DistrictSwitcher from "./components/DistrictSwitcher";
import DistrictSwitcherUI from "./components/DistrictSwitcherUI";

const authContext = new AuthContext();

const switchDistrictElement = document.getElementById("switch-district");
if (switchDistrictElement) {
    authContext.loadSate().then((state) => {
        const root = ReactDOM.createRoot(switchDistrictElement);
        root.render(
            <StrictMode>
                <DistrictSwitcher authContext={authContext} />
                <DistrictSwitcherUI authContext={authContext} />
            </StrictMode>
        );
    });
}
